import React, { useState, useEffect } from 'react';
import ErstelleRechnung from './ErstelleRechnung';
import DurchsucheRechnungen from './DurchsucheRechnungen';
import UserHinzufuegen from './UserHinzufuegen';
import DocumentsPage from './DocumentsPage';
import { useNavigate } from 'react-router-dom';

function Dashboard({ onLogout }) {
  const [view, setView] = useState('durchsuche');
  const [userRole, setUserRole] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    // Decode the token to get the role (or validate the token if needed)
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setUserRole(decodedToken.role);
    } catch (e) {
      console.error('Fehler beim Dekodieren des Tokens:', e);
      navigate('/login');
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    onLogout(); // Call the onLogout function passed from App
    navigate('/login'); // Redirect to the login page
  };

  return (
    <div className="dashboard">
      <div className="button-container">
        {/* Conditional rendering based on user role */}
        {(userRole !== 'staatsanwalt' && userRole !== 'gericht') && (
          <>
            <button onClick={() => setView('durchsuche')}>Durchsuche Rechnungen</button>
            {userRole !== 'aufsicht' && (
              <button onClick={() => setView('erstelle')}>Erstelle Rechnung</button>
            )}
          </>
        )}
        {userRole === 'admin' && (
          <button onClick={() => setView('benutzer')}>User Hinzufügen</button>
        )}
        <button onClick={() => setView('dokumente')}>Dokumente</button> {/* Button für Dokumente */}
        <button onClick={handleLogout}>Logout</button>
      </div>

      {view === 'durchsuche' && (userRole !== 'staatsanwalt' && userRole !== 'gericht') && <DurchsucheRechnungen />}
      {view === 'erstelle' && !['aufsicht', 'staatsanwalt', 'gericht'].includes(userRole) && <ErstelleRechnung />}
      {view === 'benutzer' && userRole === 'admin' && <UserHinzufuegen />}
      {view === 'dokumente' && <DocumentsPage />}
    </div>
  );
}

export default Dashboard;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Funktion zum Formatieren des Datums im deutschen Format
function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

function DurchsucheRechnungen() {
  const [rechnungen, setRechnungen] = useState([]);
  const [selectedFall, setSelectedFall] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const apiUrl = process.env.REACT_APP_API_URL; // API-URL aus Umgebungsvariable

    axios.get(`${apiUrl}/rechnungen/all`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => setRechnungen(res.data))
      .catch(err => alert('Fehler beim Laden der Rechnungen'));
  }, []);

  const handleClick = (rechnung) => {
    // Toggle selected state
    if (selectedFall && selectedFall.fallnummer === rechnung.fallnummer) {
      setSelectedFall(null); // Wenn der aktuell ausgewählte Fall erneut angeklickt wird, schließe ihn
    } else {
      setSelectedFall(rechnung); // Andernfalls, setze den ausgewählten Fall auf den angeklickten
    }
  };

  return (
    <div>
      <h2>Durchsuche Rechnungen</h2>
      <ul>
        {rechnungen.map((rechnung) => (
          <li 
            key={rechnung.fallnummer} 
            style={{ marginBottom: '20px' }}
          >
            <div 
              onClick={() => handleClick(rechnung)}
              style={{ cursor: 'pointer' }}
            >
              {rechnung.mandant} / {rechnung.fallnummer} / {formatDate(rechnung.datum)}
            </div>
            {selectedFall && selectedFall.fallnummer === rechnung.fallnummer && (
              <div style={{ marginTop: '10px', padding: '10px', border: '1px solid #ddd' }}>
                <h4>Details zu Fallnummer {selectedFall.fallnummer}</h4>
                <p><strong>Vertretender Anwalt:</strong> {selectedFall.vertretender_anwalt}</p>
                <p><strong>Mandant:</strong> {selectedFall.mandant}</p>
                <p><strong>Anklagepunkte:</strong> {selectedFall.anklagepunkte}</p>
                <p><strong>Hafteinheiten:</strong> {selectedFall.hafteinheiten}</p>
                <p><strong>Summe:</strong> {selectedFall.summe}€</p>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DurchsucheRechnungen;

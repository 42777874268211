import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ErstelleRechnung.css'; // Importiere die CSS-Datei für spezifisches Styling

// API-URL aus der Umgebungsvariable beziehen
const apiUrl = process.env.REACT_APP_API_URL;

function ErstelleRechnung() {
  const [form, setForm] = useState({
    vertretender_anwalt: '',
    fallnummer: '',
    datum: '',
    mandant: '',
    anklagepunkte: '',
    hafteinheiten: 0,
    sonstige_ausgaben: 0.00,
    summe: 0.00
  });

  useEffect(() => {
    const berechneSumme = () => {
      const hafteinheitenSumme = form.hafteinheiten * 2000;
      const gesamtSumme = hafteinheitenSumme + parseFloat(form.sonstige_ausgaben);
      setForm({ ...form, summe: gesamtSumme.toFixed(2) });
    };

    berechneSumme();
  }, [form.hafteinheiten, form.sonstige_ausgaben]);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    axios.post(`${apiUrl}/rechnungen/create`, form, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(res => alert('Rechnung erfolgreich erstellt'))
      .catch(err => alert('Fehler beim Erstellen der Rechnung'));
  };

  return (
    <form onSubmit={handleSubmit} className="rechnung-form">
      <h2>Erstelle Rechnung</h2>
      
      <label>Vertretender Anwalt</label>
      <input
        type="text"
        name="vertretender_anwalt"
        value={form.vertretender_anwalt}
        onChange={handleChange}
        placeholder="Vertretender Anwalt"
        required
      />

      <label>Fallnummer</label>
      <input
        type="text"
        name="fallnummer"
        value={form.fallnummer}
        onChange={handleChange}
        placeholder="Fallnummer"
        required
      />

      <label>Datum</label>
      <input
        type="date"
        name="datum"
        value={form.datum}
        onChange={handleChange}
        placeholder="Datum"
        required
      />

      <label>Mandant</label>
      <input
        type="text"
        name="mandant"
        value={form.mandant}
        onChange={handleChange}
        placeholder="Mandant"
        required
      />

      <label>Anklagepunkte</label>
      <textarea
        name="anklagepunkte"
        value={form.anklagepunkte}
        onChange={handleChange}
        placeholder="Anklagepunkte"
        required
      ></textarea>

      <label>Hafteinheiten</label>
      <input
        type="number"
        name="hafteinheiten"
        value={form.hafteinheiten}
        onChange={handleChange}
        placeholder="Hafteinheiten"
        required
        min="0"
      />

      <label>Sonstige Ausgaben</label>
      <input
        type="number"
        step="0.01"
        name="sonstige_ausgaben"
        value={form.sonstige_ausgaben}
        onChange={handleChange}
        placeholder="Sonstige Ausgaben"
        required
        min="0"
      />

      <label>Summe</label>
      <input
        type="number"
        step="0.01"
        name="summe"
        value={form.summe}
        readOnly
        placeholder="Summe"
      />

      <div className="submit-container">
        <button type="submit">Rechnung Erstellen</button>
      </div>
    </form>
  );
}

export default ErstelleRechnung;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

function UserHinzufuegen() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user');
  const [message, setMessage] = useState('');
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    // Get the JWT token from localStorage
    const token = localStorage.getItem('token');
    if (token) {
      // Decode the token to get the role
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setUserRole(decodedToken.role);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, 
      { username, password, role }, 
      { headers: { 'Authorization': `Bearer ${token}` } }
    )
    .then(response => {
      setMessage('Benutzer erfolgreich erstellt');
    })
    .catch(error => {
      setMessage('Fehler beim Erstellen des Benutzers');
    });
  };

  // Only show the form if the user is an admin
  if (userRole !== 'admin') {
    return <p>Sie haben keine Berechtigung, um Benutzer hinzuzufügen.</p>;
  }

  return (
    <form onSubmit={handleSubmit} className="user-form">
      <h2>Benutzer Hinzufügen</h2>
      
      <label>Benutzername</label>
      <input 
        type="text" 
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
        placeholder="Benutzername" 
        required
      />

      <label>Passwort</label>
      <input 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        placeholder="Passwort" 
        required
      />

      <label>Rolle</label>
      <select value={role} onChange={(e) => setRole(e.target.value)}>
        <option value="user">Benutzer</option>
        <option value="admin">Admin</option>
        <option value="aufsicht">Aufsicht</option>
        <option value="staatsanwalt">Staatsanwalt</option>
        <option value="gericht">Gericht</option>

      </select>

      <button type="submit">Benutzer Erstellen</button>
      {message && <p>{message}</p>}
    </form>
  );
}

export default UserHinzufuegen;

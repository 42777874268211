import React, { useState } from 'react';
import axios from 'axios';

// API-URL aus der Umgebungsvariable beziehen
const apiUrl = process.env.REACT_APP_API_URL;

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${apiUrl}/auth/login`, { username, password })
      .then(res => {
        localStorage.setItem('token', res.data.token);
        onLogin();
      })
      .catch(err => alert('Login fehlgeschlagen'));
  };

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src="/logo.png" alt="Kanzlei Logo" className="logo" />
        <h1>Rechtsverdreher GbR</h1>
      </div>
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Benutzername"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Passwort"
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DocumentsPage = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterRole, setFilterRole] = useState(''); // Rolle zum Filtern
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [uploadFile, setUploadFile] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const fetchDocuments = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/all`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setDocuments(response.data);
        setFilteredDocuments(response.data);
        setLoading(false);
      } catch (err) {
        console.error('Fehler beim Laden der Dokumente:', err);
        setError('Fehler beim Laden der Dokumente');
        setLoading(false);
      }
    };

    fetchDocuments();
  }, []);

  const handleFilterChange = (role) => {
    setFilterRole(role);
    if (role === '') {
      setFilteredDocuments(documents);
    } else {
      const filtered = documents.filter(doc => doc.role.includes(role));
      setFilteredDocuments(filtered);
    }
  };

  const handleFileChange = (e) => {
    setUploadFile(e.target.files[0]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files[0];
    if (file && file.type === 'application/pdf') {
      setUploadFile(file);
    } else {
      alert('Bitte nur PDF-Dateien hochladen.');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleUpload = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('document', uploadFile);
    formData.append('title', title);
    formData.append('description', description);
    formData.append('role', role);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/documents/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      alert('Dokument erfolgreich hochgeladen!');
      setUploadFile(null);
      setTitle('');
      setDescription('');
      setRole('');
      // Optionally, refresh the document list
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/documents/all`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setDocuments(response.data);
      setFilteredDocuments(response.data);
    } catch (err) {
      console.error('Fehler beim Hochladen des Dokuments:', err);
      alert('Fehler beim Hochladen des Dokuments');
    }
  };

  const handleDownload = async (filename) => {
    const token = localStorage.getItem('token');
    
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/download/${filename}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        responseType: 'blob' // Wichtig für den Download von Dateien
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Fehler beim Herunterladen:', error);
      alert('Fehler beim Herunterladen der Datei');
    }
  };

  if (loading) return <p>Lade Dokumente...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <h1>Dokumente</h1>
      
      {/* Filter Dropdown */}
      <div>
        <label>Filtern nach Rolle: </label>
        <select value={filterRole} onChange={(e) => handleFilterChange(e.target.value)}>
          <option value="">Alle Rollen</option>
          <option value="admin">Admin</option>
          <option value="user">User</option>
          <option value="staatsanwalt">Staatsanwalt</option>
          <option value="gericht">Gericht</option>
        </select>
      </div>

      {/* Dokumentenliste */}
      <div className="documents-list">
        {filteredDocuments.length > 0 ? (
          filteredDocuments.map((document) => (
            <div key={document._id} className="document-card">
              <h3>{document.title}</h3>
              <p>{document.description}</p>
              <p>Für Rollen: {document.role.join(', ')}</p>
              <button onClick={() => handleDownload(document.filePath.split('/').pop())}>
                PDF Herunterladen
              </button>
            </div>
          ))
        ) : (
          <p>Keine Dokumente gefunden.</p>
        )}
      </div>

      {/* Upload Form */}
      <form onSubmit={handleUpload} className="upload-form">
        <div
          className="dropzone"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {uploadFile ? (
            <div>{uploadFile.name}</div>
          ) : (
            <p>Ziehe eine PDF-Datei hierher zum hochladen.</p>
          )}
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="file-input"
          />
          <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
            Datei auswählen
          </label>
        </div>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Titel"
          required
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Beschreibung"
          required
        />
        <input
          type="text"
          value={role}
          onChange={(e) => setRole(e.target.value)}
          placeholder="Rollen (kommagetrennt)"
          required
        />
        <button type="submit">Dokument hochladen</button>
      </form>
    </div>
  );
};

export default DocumentsPage;
